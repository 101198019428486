import { Box } from 'theme-ui';
import BeatLoader from 'react-spinners/BeatLoader';
import { AnimatePresence, motion } from 'framer-motion';

import { theme } from 'theme';
import { Icon } from '../Icon';

const BoxMotion = motion(Box);

export const LoadingScreen = ({ children, ...props }) => {
    return (
        <AnimatePresence initial={false}>
            <BoxMotion>
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        bg: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: 7,
                        fontWeight: 700,
                        opacity: 1,
                        pointerEvents: 'auto',
                        zIndex: 999,
                        flexDirection: 'column',
                    }}
                >
                    <Icon icon="logo-red" sx={{ width: '250px', mb: 2 }} />
                    <BeatLoader size="22px" color={theme.colors.secondary} />
                </Box>
                {children}
            </BoxMotion>
        </AnimatePresence>
    );
};
