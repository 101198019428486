import { Text, Link } from '@theme-ui/components';
import { Icon } from 'components/Common/Icon';
import { useBreakpointIndex } from '@theme-ui/match-media';

export const Phone = () => {
    const breakpointIndex = useBreakpointIndex();

    return (
        <Link
            href="tel:1300456692"
            color="black    "
            sx={{
                display: 'inline-flex',
                textDecoration: 'none',
                alignItems: 'center',
                flexWrap: 'nowrap',
                transition: 'color 0.4s ease',
                '&:hover, &:focus': {
                    color: 'white',
                },
            }}
        >
            <Icon icon="phone" size="23px" />

            {breakpointIndex > 0 && (
                <Text as="span" variant="lead" color="inherit" pl="11px">
                    1300 456 692
                </Text>
            )}
        </Link>
    );
};
