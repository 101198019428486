import { theme } from 'theme';

//custom react-select overrides
//docs: https://react-select.com/styles

export const customStyles = {
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        backgroundColor: state.isSelected ? theme.colors.primary : (state.isFocused ? theme.colors.grayLight : 'transparent'),
        fontSize: '13px',
        fontWeight: 500,
        '&:hover': {
            backgroundColor: theme.colors.grayLight,
        },
    }),
    control: (provided, state) => ({
        ...provided,
        ...provided,
        padding: '8px 14px',
        minHeight: 0,
        borderColor: state.isFocused ? theme.colors.primary : theme.colors.grayLight,
        boxShadow: 'none',
        borderRadius: 4,
        fontSize: '14px',
        fontWeight: 700,
        '&:hover, &:focus': {
            border: `1px solid ${(props) => (props.invalid ? '#C0D33C' : (state.isFocused ? theme.colors.primary : theme.colors.grayLight))}`,
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        fontWeight: 400,
        overflow: 'visible',
        top: '7px',
        margin: 0,
        padding: 0,
        fontSize: '14px',
        alignItems: 'flex-start',
        color: theme.colors.grayDark,
    }),
    singleValue: (provided) => ({
        ...provided,
        color: theme.colors.grayDark,
        margin: 0,
        padding: 0,
    }),
    input: (provided) => ({
        ...provided,
        color: theme.colors.grayDark,
        margin: 0,
        padding: 0,
        cursor: 'pointer',
    }),
    noOptionsMessage: (provided) => ({
        ...provided,
        color: 'black',
        fontSize: '13px',
        fontWeight: 500,
    }),
    loadingMessage: (provided) => ({
        ...provided,
        color: 'black',
        fontSize: '13px',
        fontWeight: 500,
    }),
    placeholder: (provided) => ({
        ...provided,
        color: theme.colors.grayDark,
        fontWeight: 400,
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 10,
        margin: '3px 0',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        padding: '6px 0 6px 6px',

        '> div': {
            padding: 0,
        },
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: theme.colors.secondary,

        svg: {
            width: '22px',
            height: '22px',
        },
    }),
};
