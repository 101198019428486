/** @jsxImportSource theme-ui */
import { Box, Flex, Text } from '@theme-ui/components';
import { Themed } from '@theme-ui/mdx';

import { Container } from 'components/Common/Container';
import { ContentBox } from 'components/Common/ContentBox';
import { Headline } from 'components/Common/Headline';
import { Icon } from 'components/Common/Icon';
import { motion } from 'framer-motion';
import { fadeIn } from 'utils/animations';

const FlexMotion = motion(Flex);

export const Confirmation = ({ quoteData, ...props }) => {
    const high_load = JSON.parse(process.env.REACT_APP_HIGH_LOAD);
    const exports = quoteData.exports !== null && [
        {
            title: 'Your Novated Lease Application',
            file: quoteData.exports.lease,
        },
        {
            title: 'Your Insurance Declaration',
            file: quoteData.exports.insurer,
        },
        {
            title: 'Your Privacy and Consent',
            file: quoteData.exports.financier,
        },
        {
            title: 'Your Signed Novated Lease Quote',
            file: quoteData.exports.quote,
        },
    ];

    const contactDetails = [];
    if (quoteData.quote?.quoted_by?.email !== null && quoteData.quote?.quoted_by?.email !== undefined) {
        contactDetails.push({
            label: 'Email',
            title: quoteData.quote.quoted_by.email,
            link: 'mail:' + quoteData.quote.quoted_by.email,
        });
    }
    if (quoteData.quote?.quoted_by?.phone !== null && quoteData.quote?.quoted_by?.phone !== undefined) {
        contactDetails.push({
            label: 'Phone',
            title: quoteData.quote.quoted_by.phone,
            link: 'tel:' + quoteData.quote.quoted_by.phone.replace(/[^0-9+-]/g, ''),
        });
    }

    const contactElements = contactDetails.map((contact, index) => (
        <Text sx={{ text: 'white' }} key={index}>
            {contact.label}:{' '}
            <Text
                as="a"
                href={contact.link}
                sx={{
                    color: 'inherit',
                    textDecoration: 'none',
                    transition: 'easeDefault',
                    '&:hover': {
                        color: 'primary',
                    },
                }}
            >
                {contact.title}
            </Text>
            <br />
        </Text>
    ));

    const exportElements = exports.map((pdf, index) =>
        pdf.file !== null ? (
            <Text
                key={index}
                as="a"
                variant="label"
                href={pdf.file}
                rel="noreferrer"
                target="_blank"
                sx={{
                    textDecoration: 'none',
                    display: 'flex',
                    mb: 2,
                    transition: 'easeDefault',
                    '&:hover': {
                        color: 'secondary',
                    },
                }}
            >
                <Icon icon="upload" sx={{ flexShrink: 0, mr: 1, color: 'secondary' }} size="18px" />
                {pdf.title}
            </Text>
        ) : null
    );

    return (
        <FlexMotion
            {...fadeIn}
            sx={{
                flexDirection: 'column',
                flexGrow: 1,
                px: [0, null, null, 7],
                maxWidth: '980px',
            }}
        >
            <Container
                sx={{
                    mt: ['auto', null, null, '10vh'],
                    mb: [4, 5],
                    p: [0, null, null, 0],
                    px: [20, null, null, 0],
                    maxWidth: ['100%', '', '', 'none'],
                }}
            >
                <Headline
                    sx={{
                        pt: [4, 0],
                        mb: 3,
                        textAlign: 'left',
                        maxWidth: 'none',
                        color: 'white',
                    }}
                >
                    Thanks {quoteData.personal?.first_name}!
                    <br />
                    <Text as="span" color="primary">
                        We are reviewing
                        <br />
                        your application.
                    </Text>
                </Headline>

                <Text sx={{ color: 'white' }}>
                    {high_load ? (
                        <>Thank you for your application, due to high volumes applications are taking 5-10 business days, please be patient whilst we work through this period. Your novated leasing specialist and our support team will be in touch.</>
                    ) : (
                        <>
                            Expect an update within 48 hours. For any queries please call us on{' '}
                            <Text
                                as="a"
                                href="tel:1300266828"
                                sx={{
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    transition: 'easeDefault',
                                    '&:hover': {
                                        color: 'primary',
                                    },
                                }}
                            >
                                1300 456 692
                            </Text>
                        </>
                    )}
                </Text>
            </Container>

            <Container
                sx={{
                    px: [20, null, null, 0],
                }}
            >
                <ContentBox>
                    <Themed.h4 sx={{ mt: 0, mb: 2 }}>Your Submitted Application</Themed.h4>
                    <Text as="p" color="grayDark" sx={{ mb: 4 }}>
                        {quoteData.exports !== null ? 'You may come back to download these documents at any time.' : 'We have sent you an email with a link to download the submitted documents.'}
                    </Text>

                    <Box>{exportElements}</Box>
                </ContentBox>
            </Container>

            <Container
                sx={{
                    mt: [4, 5],
                    mb: [4, 5],
                    p: [0, null, null, 0],
                    px: [20, null, null, 0],
                    maxWidth: ['100%', '', '', 'none'],
                }}
            >
                <Text as="p" sx={{ color: 'white', mb: 2 }}>
                    Contact your consultant directly
                </Text>
                <Text sx={{ color: 'white' }}>
                    {quoteData.quote.quoted_by?.name}
                    <br />
                    {contactElements}
                </Text>
            </Container>
        </FlexMotion>
    );
};
