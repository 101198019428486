const fontSizesMobile = [10, 14, 16, 19, 23, 29, 33, 36, 39];

export const theme = {
    breakpoints: ['576px', '768px', '992px', '1290px', '1600px', '1920px'],
    sizes: {
        0: '0',
        1: '0.25rem',
        2: '0.5rem',
        3: '0.75rem',
        4: '1rem',
        5: '1.25rem',
        6: '1.5rem',
        8: '2rem',
        10: '2.5rem',
        12: '3rem',
        16: '4rem',
        20: '5rem',
        24: '6rem',
        32: '8rem',
        40: '10rem',
        48: '12rem',
        56: '14rem',
        64: '16rem',
        '1/2': '50%',
        '1/3': '33.333333%',
        '2/3': '66.666667%',
        '1/4': '25%',
        '2/4': '50%',
        '3/4': '75%',
        '1/5': '20%',
        '2/5': '40%',
        '3/5': '60%',
        '4/5': '80%',
        '1/6': '16.666667%',
        '2/6': '33.333333%',
        '3/6': '50%',
        '4/6': '66.666667%',
        '5/6': '83.333333%',
        '1/12': '8.333333%',
        '2/12': '16.666667%',
        '3/12': '25%',
        '4/12': '33.333333%',
        '5/12': '41.666667%',
        '6/12': '50%',
        '7/12': '58.333333%',
        '8/12': '66.666667%',
        '9/12': '75%',
        '10/12': '83.333333%',
        '11/12': '91.666667%',
        full: '100%',
        screenHeight: '100vh',
        screenWidth: '100vw',
    },
    space: [0, 4, 8, 18, 30, 48, 64, 128, 256, 512],
    fonts: {
        body: '"Replica", "Helvetica Neue", sans-serif',
        heading: 'inherit',
        monospace: 'Menlo, monospace',
    },
    fontSizes: [12, 14, 16, 23, 26, 33, 39, 48, 60],
    fontWeights: {
        body: 400,
        heading: 700,
        bold: 700,
    },
    lineHeights: {
        body: 1.5,
        heading: 1.125,
    },
    colors: {
        primary: '#000',
        primaryHover: '#000',
        secondary: '#C0D33C',
        secondaryHover: '#818285',
        muted: '#f6f6f6',
        background: '#fff',
        offWhite: '#F4F8F9',
        transparent: 'transparent',
        black: '#272932',
        white: '#fff',
        gray: '#818285',
        grayDark: '#6B7788',
        grayLight: '#D5D5E5',
        success: '#9ae6b4',
        info: '#63b3ed',
        warning: '#faf089',
        danger: '#E11900',
        error: '#E11900',
        light: '#f7fafc',
        dark: '#2d3748',
        text: '#000',
        textMuted: '#718096',
    },
    text: {
        headline: {
            fontSize: [fontSizesMobile[8], 8],
            lineHeight: 0.9,
            fontWeight: 'bold',
            color: 'text',
        },
        intro: {
            fontSize: [fontSizesMobile[4], 4],
            fontWeight: 'body',
            color: 'text',
        },
        lead: {
            fontSize: [fontSizesMobile[3], 3],
            color: 'text',
        },
        body: {
            fontSize: [fontSizesMobile[2], 2],
            color: 'text',
        },
        button: {
            fontSize: [fontSizesMobile[2], 2],
            fontWeight: 'heading',
            color: 'text',
        },
        label: {
            fontSize: [fontSizesMobile[0], 1],
            fontWeight: 'heading',
            color: 'text',
        },
        heading: {
            color: 'primary',
            fontFamily: 'heading',
            lineHeight: 'heading',
            fontWeight: 'heading',
            fontSize: '100px',
        }
    },
    buttons: {
        primary: {
            backgroundColor: 'secondary',
            borderColor: 'secondary',
            '&:hover': {
                backgroundColor: 'primaryHover',
                borderColor: 'primaryHover',
            },
        },
        secondary: {
            backgroundColor: 'primary',
            borderColor: 'primary',
            '&:hover': {
                backgroundColor: 'secondaryHover',
                borderColor: 'secondaryHover',
            },
        },
        bordered: {
            backgroundColor: 'transparent',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: 'secondary',
            color: 'secondary',
            '&:hover': {
                backgroundColor: 'secondary',
                color: 'white',
            },
        },
        pill: {
            backgroundColor: 'white',
            border: 'none',
            borderRadius: ['15px', '30px'],
            color: 'primary',
            fontSize: [fontSizesMobile[0], 1],
            padding: '8px 20px',
            fontWeight: 700,
            transition: 'easeDefault',
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            '&:hover': {
                backgroundColor: 'secondary',
                color: 'white',
            },
        },
    },
    styles: {
        root: {
            fontFamily: 'body',
            lineHeight: 'body',
            fontWeight: 'body',
            fontSize: [fontSizesMobile[1], 2],
            WebkitFontSmoothing: 'antialiased',
            textRendering: 'optimizeLegibility',
            scrollBehavior: 'smooth',
        },
        h1: {
            color: 'text',
            fontFamily: 'heading',
            fontWeight: 'heading',
            fontSize: [fontSizesMobile[6], 6],
        },
        h2: {
            color: 'text',
            fontFamily: 'heading',
            fontWeight: 'heading',
            fontSize: [fontSizesMobile[5], 5],
        },
        h3: {
            color: 'text',
            fontFamily: 'heading',
            fontWeight: 'heading',
            fontSize: [fontSizesMobile[4], 4],
        },
        h4: {
            color: 'text',
            fontFamily: 'heading',
            fontWeight: 'heading',
            fontSize: [fontSizesMobile[3], 3],
        },
        h5: {
            color: 'text',
            fontFamily: 'heading',
            fontWeight: 'heading',
            fontSize: [fontSizesMobile[2], 2],
        },
        h6: {
            color: 'text',
            fontFamily: 'heading',
            fontWeight: 'heading',
            fontSize: [fontSizesMobile[1], 1],
        },
        p: {
            color: 'text',
            fontFamily: 'body',
            fontWeight: 'body',
            lineHeight: 'body',
            fontSize: '12px',
        },
        a: {
            color: 'text',
        },
        pre: {
            fontFamily: 'monospace',
            overflowX: 'auto',
            code: {
                color: 'inherit',
            },
        },
        code: {
            fontFamily: 'monospace',
            fontSize: 'inherit',
        },
        table: {
            width: '100%',
            borderCollapse: 'separate',
            borderSpacing: 0,
        },
        th: {
            textAlign: 'left',
            borderBottomStyle: 'solid',
        },
        td: {
            textAlign: 'left',
            borderBottomStyle: 'solid',
        },
        img: {
            maxWidth: '100%',
        },
    },
    forms: {
        select: {
            '& + svg': {
                ml: '-25px',
            },
            fontFamily: 'body',
        },
        input: {
            fontFamily: 'body',
        },
        textarea: {
            fontFamily: 'body',
        },
        readonly: {
            bg: 'gray',
            fontWeight: 'bold'
        },
        slider: {
            // https://blog.logrocket.com/creating-custom-css-range-slider-javascript-upgrades/
            webkitAppearance: 'none',
            appearance: 'none',
            cursor: 'pointer',
            width: '100%',
            outline: 'none',
            height: '16px',

            /* Hover, active & focus Thumb: Webkit */
            '::-webkit-slider-thumb:hover': {
                boxShadow: '0 0 0 6px rgba(0, 0, 0, .1)'
            },
            ':active::-webkit-slider-thumb': {
                boxShadow: '0 0 0 6px rgba(0, 0, 0, .2)'
            },
            ':focus::-webkit-slider-thumb': {
                boxShadow: '0 0 0 6px rgba(0, 0, 0, .2)'
            },
            '::-moz-range-thumb:hover': {
                boxShadow: '0 0 0 6px rgba(0, 0, 0, .1)'
            },
            ':active::-moz-range-thumb': {
                boxShadow: '0 0 0 6px rgba(0, 0, 0, .2)'
            },
            ':focus::-moz-range-thumb': {
                boxShadow: '0 0 0 6px rgba(0, 0, 0, .2)'
            },

            // Thumb styles
            '::-webkit-slider-thumb': {
                webkitAppearance: 'none',
                appearance: 'none',
                bg: 'primary',
                height: '30px',
                width: '30px',
                border: 'none',
                borderRadius: '50%',
                transition: '.2s ease-in-out',
            },
            '::-moz-range-thumb': {
                webkitAppearance: 'none',
                appearance: 'none',
                bg: 'primary',
                height: '30px',
                width: '30px',
                border: 'none',
                borderRadius: '50%',
                transition: '.2s ease-in-out',
            }
        }
    },
    transitions: {
        easeDefault: '0.5s ease',
    },
};
