import { Flex, Link, Text } from '@theme-ui/components';
import { useBreakpointIndex } from '@theme-ui/match-media';
import dayjs from 'dayjs';

export const Footer = ({ children, ...props }) => {
    const breakpointIndex = useBreakpointIndex();

    if (breakpointIndex < 3) {
        return '';
    }

    return (
        <Flex
            color="white"
            sx={{
                justifyContent: 'flex-end',
                flexWrap: 'nowrap',
                fontWeight: 400,
                fontSize: 12,
                color: 'grayDark',
                p: 4,
                pt: 3,
                mt: 'auto',
            }}
        >
            <Text as="p" mr={3}>
                Kooya Copyright {dayjs().format('YYYY')}
            </Text>

            <Text as="p">
                Site by{' '}
                <Link
                    color="grayDark"
                    href="https://www.juicebox.com.au"
                    target="_blank"
                    sx={{
                        textDecoration: 'none',
                        transition: 'easeDefault',
                        '&:hover': {
                            color: 'white',
                        },
                    }}
                >
                    Juicebox
                </Link>
            </Text>
        </Flex>
    );
};
