import React, { createContext, useEffect, useState } from 'react';

export const MarkdownContext = createContext();

export const MarkdownProvider = ({ children }) => {
    const [mdContent, setMdContent] = useState({});

    const markdownFiles = [
        'financier-103924',
        'financier-103939',
        'financier-105097',
        'financier-103942',
        'financier-102325',
        'declaration',
    ];

    useEffect(() => {
        markdownFiles.forEach((file) => {
            const getContent = async () => {
                const markdown = require(`content/${file}.md`);
                const response = await fetch(markdown.default);
                const content = await response.text();
                setMdContent((mdContent) => ({ ...mdContent, [file]: content }));
            };

            getContent();
        });
        // eslint-disable-next-line
    }, []);

    return (
        <MarkdownContext.Provider
            value={{
                ...mdContent,
            }}
        >
            {children}
        </MarkdownContext.Provider>
    );
};
