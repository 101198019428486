import { Box, Text } from '@theme-ui/components';
import BeatLoader from 'react-spinners/BeatLoader';
import { Form, Formik } from 'formik';
import { useHistory, useParams } from 'react-router';
import * as Yup from 'yup';
import { useState } from 'react';

import { Signature } from 'components/FormInputs/Signature';
import { Submit } from 'components/FormInputs/Submit';
import { Button } from 'components/Common/Button';
import { useUploadSignature } from 'pages/Acceptance/hooks/useUploadSignature';
import { useQuote } from 'pages/Acceptance/hooks/useQuote';
import { useApproveQuote } from 'pages/Acceptance/hooks/useApproveQuote';
import { base64Validation } from 'utils/helpers';
import { theme } from 'theme';
import { Themed } from '@theme-ui/mdx';

export const SubmitStage = ({
    onClose
}) => {
    const history = useHistory();
    const { quoteUuid } = useParams();

    const uploadSignature = useUploadSignature();
    const approveQuote = useApproveQuote();
    const { data: quoteData } = useQuote({ quoteUuid, getToken: true });
    const [redo, setRedo] = useState(quoteData.signature?.image === null);

    return (
        <>
            <Box sx={{ textAlign: 'center', mb: 5 }}>
                <Text
                    as="p"
                    variant="lead"
                    sx={{ maxWidth: 400, textAlign: 'center', m: '0 auto', mb: 4 }}
                >
                    By signing below I acknowledge that all the information provided is correct.
                </Text>

                <Formik
                    enableReinitialize
                    validationSchema={Yup.object({
                        signature: base64Validation.nullable()
                    })}
                    initialValues={{
                        signature: null
                    }}
                    onSubmit={async ({ signature, ...values }) => {
                        let approve = true;

                        if(signature !== null){
                            approve = false;

                            // upload signature first
                            const sigUpload = await uploadSignature.mutateAsync({
                                user_uuid: quoteData.personal?.uuid,
                                signature,
                            });

                            // send remaining data if sig upload successful
                            if (sigUpload.success) {
                                approve = true;
                            }
                        }

                        if(approve){
                            let quoteData = await approveQuote.mutateAsync(quoteUuid);
                            if (quoteData.success) {
                                history.push(`/quote/${quoteUuid}`);
                            }
                        }
                    }}
                >
                    {({isSubmitting}) => (
                        <>
                            <Form>
                                { (!redo && quoteData?.signature?.image) &&
                                    <Box sx={{ maxWidth: 400, textAlign: 'center', m: '0 auto', mb: 4 }}>
                                        <img src={quoteData.signature?.image} alt="" />
                                    </Box>
                                }

                                {!isSubmitting && <>
                                    {!redo &&
                                        <Box sx={{ maxWidth: 400, textAlign: 'center', m: '0 auto', mb: 4 }}>
                                            <Button
                                                variant="bordered"
                                                name="redo_signature"
                                                type="button"
                                                onClick={() => setRedo(true)}
                                            >
                                            Replace signature
                                            </Button>
                                        </Box>
                                    }
                                    </>
                                }

                                {redo &&
                                    <Signature
                                        name="signature"
                                        placeholder="Signature"
                                        sx={{
                                            textAlign: 'center',
                                            mx: 'auto',
                                            maxWidth: 330,
                                        }}
                                    />
                                }



                                {!isSubmitting &&
                                    <>
                                        <Submit
                                            text="Submit Application"
                                            submittingText="Submitting..."
                                        />
                                        <Button
                                            mt={[0,0,0,4]}
                                            ml={[0,0,2,2]}
                                            variant="bordered"
                                            name="cancel"
                                            type="button"
                                            onClick={onClose}
                                        >
                                        Back
                                        </Button>
                                    </>
                                }
                            </Form>

                            {isSubmitting && <>
                                <Box mt={4}>
                                    <Themed.h4 sx={{ mt: 0, mb: 2 }}>
                                        Submitting your application...
                                    </Themed.h4>
                                    <Text as="p" mb={2}>
                                        Please leave the window open while we process the documents.
                                    </Text>
                                </Box>
                                <BeatLoader size="22px" color={theme.colors.secondary} />
                            </>}
                        </>
                    )}
                </Formik>
            </Box>

            <Text
                as="p"
                sx={{
                    textAlign: 'center',
                    mb: 0,
                    color: 'grayDark',
                    lineHeight: 1.75,
                }}
            >
                You will be able to download copies of your application at the next step
            </Text>
        </>
    );
};
