import { useState } from 'react';
import { Box, Text } from '@theme-ui/components';
import { motion } from 'framer-motion';
import { fadeIn } from 'utils/animations';

import { Button } from 'components/Common/Button';
import { Container } from 'components/Common/Container';
import { ContentBox } from 'components/Common/ContentBox';
import { Counter } from 'components/Common/Counter';
import { Feature } from 'components/Common/Feature';
import { FeatureSummary } from 'components/Common/FeatureSummary';
import { Headline } from 'components/Common/Headline';
import { Stats } from 'components/Common/Stats';
import { Confirmation } from './components/Confirmation';
import { thousandSeparated } from 'utils/formatting';

const BoxMotion = ({ children, ...props }) => {
    return (
        <motion.div {...fadeIn} {...props}>
            {children}
        </motion.div>
    );
};

export const Acceptance = ({ quoteData, ...props }) => {
    const { quote } = quoteData;
    const [canContinue, setCanContinue] = useState(null);

    const contactDetails = [];
    if (quote?.quoted_by?.email !== null && quote?.quoted_by?.email !== undefined) {
        contactDetails.push({
            label: 'Email',
            title: quote.quoted_by.email,
            link: 'mail:' + quote.quoted_by.email,
        });
    }
    if (quote?.quoted_by?.phone !== null && quote?.quoted_by?.phone !== undefined) {
        contactDetails.push({
            label: 'Phone',
            title: quote.quoted_by.phone,
            link: 'tel:' + quote.quoted_by.phone.replace(/[^0-9+-]/g, ''),
        });
    }
    const contactElements = contactDetails.map((contact, index) => (
        <Text sx={{ text: 'white' }} key={index}>
            {contact.label}:{' '}
            <Text
                as="a"
                href={contact.link}
                sx={{
                    color: 'inherit',
                    textDecoration: 'none',
                    transition: 'easeDefault',
                    '&:hover': {
                        color: 'secondary',
                    },
                }}
            >
                {contact.title}
            </Text>
            <br />
        </Text>
    ));

    return (
        <Container>
            {canContinue === null || canContinue === false ? (
                <>
                    <Headline sx={{ mb: 4, color: 'white' }}>
                        Hi {quoteData.personal?.first_name}, <br />
                        <Text as="span" color="primary">
                            before we start
                        </Text>
                    </Headline>

                    <ContentBox
                        sx={{
                            textAlign: 'center',
                            maxWidth: 660,
                            mx: 'auto',
                            p: [4, 6],
                            minHeight: '316px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <BoxMotion key="confirmation">
                            {canContinue === null ? (
                                <>
                                    <Text as="p" variant="lead" mb={4}>
                                        Do you have any outstanding defaults, or any credit issues that may affect your application?
                                    </Text>

                                    <Box sx={{ maxWidth: 320, mx: 'auto' }}>
                                        <Button
                                            variant="bordered"
                                            mr={[0, 2]}
                                            mb={2}
                                            onClick={() => setCanContinue(false)}
                                            sx={{
                                                width: ['100%', 'auto'],
                                            }}
                                        >
                                            Yes
                                        </Button>
                                        <Button
                                            variant="bordered"
                                            mb={2}
                                            onClick={() => setCanContinue(true)}
                                            sx={{
                                                width: ['100%', 'auto'],
                                            }}
                                        >
                                            No
                                        </Button>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Text as="p" variant="lead" mb={4}>
                                        Please contact your novated lease specialist to discuss your application.
                                    </Text>
                                    <Text>
                                        {quote.quoted_by?.name}
                                        <br />
                                        {contactElements}
                                    </Text>
                                </>
                            )}
                        </BoxMotion>
                    </ContentBox>
                </>
            ) : null}

            {canContinue === true ? (
                <>
                    <Headline sx={{ mb: 4, color: 'white' }}>
                        Hi {quoteData.personal?.first_name}, enjoy a<br />
                        <Text as="span" color="primary">
                            total saving
                        </Text>{' '}
                        of
                    </Headline>

                    <Counter mb={6} start={quote.total_saving - 1000} end={quote.total_saving} />

                    <Stats
                        mb={4}
                        items={[
                            {
                                heading: thousandSeparated(quote.out_of_pocket),
                                subheading: 'Out of pocket',
                            },
                            {
                                heading: thousandSeparated(quote.new_take_home_pay),
                                subheading: 'New take home pay',
                            },
                            {
                                heading: thousandSeparated(quote.purchase_saving),
                                subheading: 'Purchase saving',
                            },
                            {
                                heading: thousandSeparated(quote.tax_saving),
                                subheading: 'Tax savings',
                            },
                        ]}
                    />

                    <FeatureSummary
                        mb={4}
                        buttonUrl={quote.pdf_url}
                        items={[
                            <Feature icon="keys" heading={`$${quote.lease_rental}`} subheading="Lease Rental" />,
                            <Feature icon="fuel" heading={`$${quote.fuel_card}`} subheading="Fuel Card" />,
                            <Feature icon="registration" heading={`$${quote.registration}`} subheading={`Registration (${quote.registration_renewals})`} />,
                            <Feature icon="maintenance" heading={`$${quote.maintenance}`} subheading="Maintenance" />,
                            <Feature icon="tyres" heading={`$${quote.tyres}`} subheading={`Tyres (${quote.tyre_notes})`} />,
                            <Feature icon="assistance" heading={`$${quote.roadside_assistance}`} subheading="NRMA Roadside Assistance" />,
                            <Feature icon="comprehensive" heading={`$${quote.car_insurance}`} subheading="Insurance" />,
                            <Feature icon="lpi" heading="Included" subheading="Lease Protection Insurance" visible={quote.show_lpi} />,
                            <Feature icon="umbrella" heading="Included" subheading="Total Loss Assist" visible={quote.show_total_loss_assist} />,
                            <Feature icon="management-outline" heading={`$${quote.admin_fee}`} subheading="Admin Fee" />,
                            <Feature icon="gap" heading="Included" subheading="Extended Warranty" visible={quote.show_extended_warranty} />,
                        ]}
                    />

                    <Confirmation mb={3} userId={quoteData.personal.uuid} quoteId={quoteData.uuid} />
                </>
            ) : null}
        </Container>
    );
};
