import { Box, Text } from '@theme-ui/components';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { useParams } from 'react-router';
import * as Yup from 'yup';

import { Container } from 'components/Common/Container';
import { ContentBox } from 'components/Common/ContentBox';
import { Headline } from 'components/Common/Headline';
import { TextInput } from 'components/FormInputs/TextInput';
import { Submit } from 'components/FormInputs/Submit';
import { OnboardConfirmation } from './OnboardConfrmation';
import { fadeIn } from 'utils/animations';
import { useOnboard } from './hooks/useOnboard';

const BoxMotion = ({ children, ...props }) => {
    return (
        <motion.div {...fadeIn} {...props}>
            {children}
        </motion.div>
    );
};

export const Onboard = ({ ...props }) => {
    const [showConfirm, setShowConfirm] = useState(false);
    const onBoard = useOnboard();
    let { quoteId } = useParams();

    const renderContent = () => {
        if (!showConfirm) {
            return (
                <BoxMotion key="form">
                    <Headline sx={{ color: 'primary', mb: 3 }}>Welcome</Headline>
                    <Text as="p" variant="lead" mb={4}>
                        Please verify your email to continue to your quote.
                    </Text>

                    <Box sx={{ maxWidth: 320, mx: 'auto' }}>
                        <Formik
                            enableReinitialize
                            onSubmit={async (values) => {
                                const response = await onBoard.mutateAsync(values);

                                if (response.success) {
                                    setShowConfirm(true);
                                }
                            }}
                            validationSchema={Yup.object({
                                email: Yup.string().email('Must be a valid email address').required('Email is required'),
                                quote_id: Yup.string().required('Quote ID is required'),
                            })}
                            initialValues={{
                                email: '',
                                quote_id: parseInt(quoteId) || '',
                            }}
                        >
                            {() => (
                                <Form>
                                    {!quoteId && <TextInput name="quote_id" label="Quote ID" mb={3} />}

                                    <TextInput name="email" label="Email" mb={3} />

                                    <Submit text="Verify Email" sx={{ width: 'full' }} />
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </BoxMotion>
            );
        } else {
            return (
                <BoxMotion key="confirmation">
                    <OnboardConfirmation />
                </BoxMotion>
            );
        }
    };

    return (
        <Box sx={{
            '&::after': {
                content: '""',
                background: `url(${process.env.PUBLIC_URL}/img/car-bg.svg) no-repeat`,
                display: 'block',
                position: 'fixed',
                width: '370px',
                height: '272px',
                bottom: 0,
                left: '20vw',
                maxWidth: '70vw',
                backgroundSize: 'contain',
                backgroundPosition: 'bottom left'
            }
        }}>
            <Container sx={{ zIndex: 10, position: 'relative' }}>
                <ContentBox
                    sx={{
                        textAlign: 'center',
                        maxWidth: 660,
                        mx: 'auto',
                        p: [4, 6],
                        minHeight: '416px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {renderContent()}
                </ContentBox>
            </Container>
        </Box>
    );
};
