import { Flex, Image } from '@theme-ui/components';
import { Link } from 'react-router-dom';
import { Phone } from './Phone';

export const Header = ({ children, ...props }) => {
    return (
        <Flex
            color="white"
            sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: [4, 5],
                p: 4,
                pt: 3,
                position: 'relative',
                zIndex: 50,
            }}
        >
            <Link to="/">
                <Image src={process.env.PUBLIC_URL + '/img/kooya-white.svg'} />
            </Link>

            <Phone />
        </Flex>
    );
};
