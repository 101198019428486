/** @jsxImportSource theme-ui */
import { Box } from '@theme-ui/components';

import { useCountries } from 'hooks/useCountries';
import { useFinanciers } from 'hooks/useFinanciers';

export const AppWrapper = ({ children, ...props }) => {
    // eslint-disable-next-line
    const { data } = useCountries();
    // eslint-disable-next-line
    const { financiers } = useFinanciers();
    // store this data in a global context for country inputs to access



    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                background:
                    'radial-gradient(114.48% 114.48% at 6% -30.34%, rgba(89, 89, 246, 0.2) 0%, rgba(47, 47, 127, 0.2) 100%), #C0D33C',
                minHeight: '100vh',
                backgroundImage: `url(${process.env.PUBLIC_URL}/img/clouds-bg.svg)`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover'
            }}
        >
            {children}
        </Box>
    );
};
