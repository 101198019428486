import styled from '@emotion/styled';

import { theme } from 'theme';
import { Button } from 'components/Common/Button';
import { useFormikContext } from 'formik';

const ButtonStyled = styled(Button)`
    align-self: flex-start;
    position: relative;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    ${(props) =>
        props.disabled &&
        `
        &:hover {
            background-color: ${theme.colors.secondary};
            border-color: ${theme.colors.secondary};
        }
    `}
`;

export const Submit = ({ type, text, ...props }) => {
    const { isSubmitting, isValid } = useFormikContext();

    return (
        <ButtonStyled
            type={type || 'submit'}
            variant="secondary"
            // variant={isValid && !isSubmitting ? 'primary' : 'disabled'}
            disabled={isSubmitting || !isValid}
            size="lg"
            {...props}
        >
            {isSubmitting
                ? props.submittingText || 'Submitting...'
                : text
                ? text
                : 'Submit'}
        </ButtonStyled>
    );
};
