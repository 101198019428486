import { Box, Flex } from '@theme-ui/components';
import { Container } from 'components/Common/Container';
import { Headline } from 'components/Common/Headline';
import { motion } from 'framer-motion';
import { fadeIn } from 'utils/animations';
import { Stages } from './components/Stages';

const FlexMotion = motion(Flex);

export const Dashboard = ({ quoteData, ...props }) => {
    return (
        <FlexMotion
            {...fadeIn}
            sx={{
                flexDirection: 'column',
                flexGrow: 1,
                px: [0, null, null, 7],
                position: 'relative',
                justifyContent: ['flex-end', 'flex-end', 'flex-end', 'flex-start'],
                bottom: [90, 90, 90, 0],
            }}
        >
            <Box>
                <Container
                    sx={{
                        mt: ['auto', 'auto', 'auto', '10vh'],
                        mb: [4, 5],
                        maxWidth: 'none',
                        p: [4, null, null, 0],
                    }}
                >
                    <Headline sx={{ pt: [4, 0], mb: 0, textAlign: 'left', color: 'white' }}>
                        3 easy steps
                        <br />
                        <Box as="span" color="primary">
                            to your new car
                        </Box>
                    </Headline>
                </Container>

                <Stages quoteData={quoteData} />
            </Box>
        </FlexMotion>
    );
};
