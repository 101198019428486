/** @jsxImportSource theme-ui */
import styled from '@emotion/styled';
import { Input } from '@theme-ui/components';
import { theme } from 'theme';

export const InputStyled = styled(Input)`
    border-radius: 4px;
    padding: 22px 14px 7px;
    border: 1px solid ${(props) => (props.invalid ? props.theme.colors.error : '#d5d5e5')};
    background: ${({ readOnly }) => (readOnly ? '#F4F8F9' : 'white')};
    width: 100%;
    font-size: 14px;
    text-overflow: ellipsis;
    padding-left: 14px;

    &::-ms-clear {
        display: none;
    }

    ::-webkit-input-placeholder {
        color: gray;
    }

    :disabled {
        background: #f9f9f9;
        border-color: #f9f9f9;
    }

    &:focus {
        outline: none;
        border-color: ${(props) => (props.invalid ? props.theme.colors.error : theme.colors.primary)};
    }
`;
